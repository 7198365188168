import React from 'react'
// import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import { animateScroll } from 'react-scroll'
import Layout from '../components/layout'
import { kotiTitleGenerator } from '../utils';

const TermsPage = () => (
  <Layout>
    <Helmet title={kotiTitleGenerator('Terms & Conditions')} />
    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1>Terms &amp; Conditions</h1>
            <h4>
              Koti is committed to protecting and respecting your privacy.
            </h4>
            <hr />
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-lg-8">
            <article>
              <span className="h4">Last updated: 2018/09/21</span>
              <p>
                In this policy:
                <br />
                “personal information” refers to any data, information, or
                combination of data and information that is provided by you to
                us, or through your use of our products or services, that
                relates to an identifiable individual; “Platform” means our
                website (https://koti.ai); “Koti” or “we” is as defined in our
                Terms of Service.
              </p>
              <p className="type--bold">
                1. What information we collect about you
              </p>
              <h5>
                1.1. We collect the following types of information about you:
              </h5>
              <p>
                a) account and profile information that you provide when you
                register for an account or sign up for our products or services,
                for example your name, email address, physical address and
                telephone number (collectively, “Account Data”);
              </p>
              <p>
                b) information you provide through our support channels, for
                example when you report a problem to us or interact with our
                support team, including any contact information, documentation,
                images or screenshots (collectively, “Support Data”);
              </p>
              <p>
                c) content you provide through use of our products or services,
                for example personal information contained in the documents that
                you create in our platform (collectively, “User Content”);
              </p>
              <p>
                d) communication, marketing, and other preferences that you set
                when you set up your account or profile, or when you participate
                in a survey or a questionnaire that we send you (collectively,
                “Preference Data”);
              </p>
              <p>
                e) details of any transactions, purchases, or orders that you’ve
                made with us (collectively, “Transaction Data”);
              </p>
              <p>
                f) payment information, for example your payment card
                information or bank account details (collectively, “Financial
                Data”);
              </p>
              <p>
                g) information about your device or connection, for example your
                internet protocol (IP) address, login data, browser type and
                version, time zone setting, browser plug-in types and versions,
                operating system and platform, and other technology on the
                devices you use to access our products or services and
                information we collect through cookies and other data collection
                technologies (please read our Cookies Policy for details)
                (collectively, “Technical Data”); and
              </p>
              <p>
                h) information about your use of or visit to our Platform, for
                example your clickstream to, through, and from our Platform,
                products you viewed, used, or searched for, download or other
                errors, page response times, length of visits to certain pages,
                page interaction information (such as scrolling, clicks, and
                mouse-overs), or methods to browse away from the page
                (collectively, “Usage Data”).
              </p>
              <h5>
                1.2. We collect the above information when you provide it to us
                or when you use or visit our Platform. We may also receive
                information about you from other sources, including:
              </h5>
              <p>
                a) our personnel, agents and consultants in connection with our
                operations or services, for example our staff engaged in the
                fulfilment of your order, processing of your payment, and
                provision of support services;
              </p>
              <p>
                b) other users of our products or services, for example when a
                member of your organisation registers an account for each team
                member on our Platform;
              </p>
              <p>
                c) other services linked to your account, for example if you
                register or log in your account using your Google credentials,
                we receive your name and email address to authenticate you, as
                permitted by your Google profile settings;
              </p>
              <p>
                d) our group companies or overseas offices that provide
                information technology, system administrative, and/or marketing
                services; and
              </p>
              <p>
                e) our business partners and service providers who provide
                technical, payment, delivery services, advertising networks,
                analytics, market research, and search information services.
              </p>
              <p>
                If you wish to purchase any product or service made available
                through the Service ("Purchase"), you may be asked to supply
                certain information relevant to your Purchase including, without
                limitation, your
              </p>
              <h5>
                1.3 . We do not collect sensitive data or special category data
                about you. This includes details about your race, ethnic origin,
                politics, religion, trade union membership, genetics,
                biometrics, health, or sexual orientation.
              </h5>
              <p className="type--bold">2. How we use information we collect</p>
              <h5>
                2.1. We only use your personal information where the law allows
                us to. We use your personal information only where:
              </h5>
              <p>
                a) we need to perform the contract we have entered into (or are
                about to enter into) with you, including to operate our products
                or services, to provide customer support and personalised
                features, and to protect the safety and security of our
                Platform;
              </p>
              <p>
                b) it satisfies a legitimate interest which is not overridden by
                your fundamental rights or data protection interests, for
                example for research and development to improve our products or
                services, and in order to protect our legal rights and
                interests;
              </p>
              <p>
                c) you’ve given us consent to do so for a specific purpose, for
                example we may send you direct marketing materials or publish
                your information as part of our testimonials or customer stories
                to promote our products or services with your permission; or
              </p>
              <p>d) we need to comply with a legal or regulatory obligation.</p>
              <h5>
                2.2. If you have given us consent to use your personal
                information for a specific purpose, you have the right to
                withdraw your consent any time by contacting us, but please note
                this will not affect any use of your information that has
                already taken place.
              </h5>
              <h5>
                2.3. We do not share your personal information with any company
                outside our group for marketing purpose, unless with your
                express specific consent to do so.
              </h5>
              <p className="type--bold">
                3. How we share information we collect
              </p>
              <h5>
                3.1. We share information with other companies in our group in
                order to operate our Platform and to offer and improve our
                products and services.
              </h5>
              <h5>
                3.2. We share information with third parties that help us
                operate, provide, support, improve, and market our products and
                services, for example third-party service providers who provide
                cloud hosting, maintenance, backup, storage, infrastructure,
                billing, payment processing, customer support, business
                analytics, and other services.
              </h5>
              <h5>
                3.3. Third-party service providers have access to your personal
                information only for the purpose of performing their services
                and in compliance with applicable laws and regulations. We
                require these third-party service providers to maintain
                confidentiality and security of all personal information that
                they process on our behalf and to implement and maintain
                reasonable security measures to protect the confidentiality,
                integrity, and availability of your personal information.
              </h5>
              <h5>
                3.4. We take reasonable steps to confirm that all third-party
                service providers that we engage process personal information in
                the manner that provides at least the same level of protection
                as is provided under this policy. Where any third- party
                provider is unable to satisfy our requirements, we will require
                them to notify us immediately and we will take reasonable steps
                to prevent or stop non-compliant processing.
              </h5>
              <h5>
                3.5. If you use any third-party software in connection with our
                products or services, for example any third-party software that
                our Platform integrates with, you might give the third-party
                software provider access to your account and information.
                Policies and procedures of third-party software providers are
                not controlled by us, and this policy does not cover how your
                information is collected or used by third-party software
                providers. We encourage you to review the privacy policies of
                third-party software providers before you use the third-party
                software.
              </h5>
              <h5>
                3.6. Our Platform may contain links to third-party websites over
                which we have no control. If you follow a link to any of these
                websites or submit information to them, your information will be
                governed by their policies. We encourage you to review the
                privacy policies of third-party websites before you submit
                information to them.
              </h5>
              <h5>
                3.7. We may share your information with government and law
                enforcement officials to comply with applicable laws or
                regulations, for example when we respond to claims, legal
                processes, law enforcement, or national security requests.
              </h5>
              <h5>
                3.8. If we are acquired by a third party as a result of a
                merger, acquisition, or business transfer, your personal
                information may be disclosed and/or transferred to a third party
                in connection with such transaction. We will notify you if such
                transaction takes place and inform you of any choices you may
                have regarding your information.
              </h5>
              <p className="type--bold">
                4. How we store and secure information we collect
              </p>
              <h5>
                4.1. We use cloud service providers to host the information we
                collect to enable us to operate as a global platform.
              </h5>
              <h5>
                4.2. We have adopted the following measures to protect the
                security and integrity of your personal information:
              </h5>
              <p>
                a) any document that you create, store, and e-sign in our
                platform is stored in encrypted form on disk and securely
                transmitted with TLS/SSL encryption; any payment transactions
                are encrypted using TLS/SSL technology;
              </p>
              <p>
                b) access to your personal information is restricted to
                personnel or service providers on a strictly need-to-know basis,
                who will only process your information on our instructions and
                who are subject to a duty of confidentiality; and
              </p>
              <p>
                c) our information collection, storage, and processing practices
                are reviewed regularly.
              </p>
              <h5>
                4.3. We have put in place procedures to deal with any suspected
                privacy breach and will notify you and any applicable regulator
                of a breach where we are legally required to do so.
              </h5>
              <h5>
                4.4. While we implement safeguards designed to protect your
                information, please note that no transmission of information on
                the Internet is completely secure.
              </h5>
              <h5>
                4.5. We cannot guarantee that your information, during
                transmission through the Internet or while stored on our systems
                or processed by us, is absolutely safe and secure. We only
                retain personal information for so long as it is reasonably
                necessary to fulfil the purposes we collected it for, including
                for the purposes of satisfying any legal, accounting, or
                reporting requirements.
              </h5>
              <h5>
                4.6. After such time, we will delete or anonymise your
                information, or if this is not possible, we will securely store
                your information and isolate it from further use. We
                periodically review the basis and appropriateness of our data
                retention policy.
              </h5>

              <p className="type--bold">
                5. How we transfer information internationally
              </p>
              <h5>
                5.1. We collect information globally. We transfer, process, and
                store your information outside your country of residence where
                we or our service providers operate for the purpose of providing
                our products and services to you.
              </h5>

              <h5>
                5.2. Some of the countries in which our companies or service
                providers are located may not have the privacy and data
                protection laws that are equivalent to those in your country of
                residence. When we share information with these companies or
                service providers, we make use of contractual clauses, corporate
                rules, and other appropriate mechanisms to safeguard the
                transfer of information.
              </h5>

              <p className="type--bold">6. Your rights</p>
              <h5>6.1. You have the right to:</h5>
              <p>
                a) be informed of what we do with your personal information;
              </p>
              <p>
                b) request a copy of personal information we hold about you;
              </p>
              <p>
                c) require us to correct any inaccuracy or error in any personal
                information we hold about you;
              </p>
              <p>
                d) request erasure of your personal information (note, however,
                that we may not always be able to comply with your request of
                erasure if the personal information is still necessary for the
                purpose which we originally collected it for, or for record
                keeping or legal compliance purposes);
              </p>
              <p>
                e) object to or restrict the processing by us of your personal
                information (including for marketing purposes);
              </p>
              <p>
                f) request to receive some of your personal information in a
                structured, commonly used, and machine readable format, and
                request that we transfer such information to another party; and
              </p>
              <p>
                g) withdraw your consent at any time where we are relying on
                consent to process your personal information (although this will
                not affect the lawfulness of any processing carried out before
                you withdraw your consent).
              </p>

              <h5>
                6.2. You may opt out of receiving marketing materials from us by
                using the unsubscribe link in our communications or by
                contacting us. Please note, however, that even if you opt out
                from receiving marketing materials from us, you will continue to
                receive notifications or information from us that are necessary
                for the use of our products or services.
              </h5>

              <h5>
                6.3. As a security measure, we may need specific information
                from you to help us confirm your identity when processing your
                privacy requests or when you exercise your rights.
              </h5>
              <h5>
                6.4. Any request under paragraph 6.1will normally be addressed
                free of charge. However, we may charge a reasonable
                administration fee if your request is clearly unfounded,
                repetitive, or excessive.
              </h5>

              <p className="type--bold">7. Changes to this policy</p>
              <p>
                We may amend this policy from time to time by posting the
                updated policy on our Platform. By continuing to use our
                Platform after the changes come into effect, you agree to be
                bound by the revised policy.
              </p>

              <p className="type--bold">8. Policy towards children</p>
              <p>
                Our products and services are not directed to individuals under
                16. We do not knowingly collect personal information from
                individuals under 16. If we become aware that an individual
                under 16 has provided us with personal information, we will take
                steps to delete such information. Contact us if you believe that
                we have mistakenly or unintentionally collected information from
                an individual under 16.
              </p>

              <p className="type--bold">9. Cookies Policy</p>
              <p>
                Cookies are small text files that are placed on your device by a
                web server when you access our Platform. We use cookies to
                identify your access and monitor usage and web traffic on our
                Platform to customise and improve our products and services. We
                use both persistent cookies and session cookies. A persistent
                cookie stays in your browser and will be read by us when you
                return to our Site or a partner site that uses our services.
                Session cookies only last for as long as the session lasts
                (usually the current visit to a website or a browser session).
              </p>

              <p>We use the following types of cookies:</p>
              <p>
                a) Strictly necessary cookies – these are cookies that are
                required for the operation of our site. They include, for
                example, cookies that enable you to log into secure areas of our
                website.
              </p>
              <p>
                b) Analytical/performance cookies – these allow us to recognise
                and count the number of visitors and to see how visitors move
                around our Site when they are using it. This helps us to improve
                the way our site works, for example, by ensuring that users are
                easily finding what they are looking for.
              </p>
              <p>
                c) Functionality cookies – these are used to recognise you when
                you return to our site.
              </p>
              <p>
                d) Targeting cookies – these cookies record your visit to our
                site, the pages you have visited, and the links you have
                followed.
              </p>
              <p>
                You can block cookies by activating the setting on your browser
                that allows you to refuse the use of all or some cookies.
                However, if you do so, you may not be able to access all or
                parts of our site.
              </p>

              <hr />
              <h5>Contact Us</h5>
              <p>
                If you have any questions about these Terms, please{' '}
                <a href="#">contact us</a>.
              </p>
            </article>
          </div>
        </div>
      </div>
    </section>
    <a
      className="back-to-top inner-link active"
      onClick={() => animateScroll.scrollToTop()}
    >
      <i className="stack-interface stack-up-open-big" />
    </a>
  </Layout>
)

export default TermsPage
